@import '../colors.scss';

.factory-circle {
  border: 1px solid $medium-champagne;
  border-radius: 10px;
  margin: 4px;

  display: grid;
  grid-template-columns: auto auto;

  &:hover {
    border-color: $gamboge;
  }

  &.selected {
    border: 1px solid $mahogany;
    background-color: rgba($rich-black-fogra-29, 0.3);
  }

  &.hidden {
    display: none;
  }

  & > * {
    align-self: center;
    justify-self: center;
  }
}
