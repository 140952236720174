@import './colors.scss';

.board {
  display: inline-flex;
  flex-direction: column;
  align-items: stretch;
  height: calc(100vh - var(--nav-bar-height));
  overflow: auto;

  @media screen and (max-aspect-ratio: 1/1) {
    height: unset;
  }

  & > h1 {
    font-size: 32px;
    display: inline-flex;
    align-items: baseline;
    justify-content: center;

    @media screen and (max-aspect-ratio: 1/1) {
      font-size: 16px;
    }
    @media screen and (max-height: #{$responsive-max-height}) {
      font-size: 16px;
    }
  }

  & .tiles {
    display: inline-flex;
    margin: 8px;

    & .left {
      border: 1px solid $blue-sapphire;
    }
    & .right {
      border: 1px solid $blue-sapphire;
    }
  }
}
