@import './colors.scss';

.score-board {
  margin: 8px;
  display: flex;
  justify-content: center;

  & > table {
    border: 1px solid $border-color;
    border-collapse: collapse;

    td {
      border-left: 1px solid $border-color;
      border-right: 1px solid $border-color;
      border-top: 1px solid $border-color;
      border-bottom: 1px solid $border-color;
      padding: 8px;
    }

    td:first-child {
      border-left: none;
    }

    td:last-child {
      border-right: none;
    }
    .cell {
      display: inline-flex;
      align-items: baseline;
    }
  }
}
