$light-grey: rgb(105, 125, 133);
$rich-black-fogra-29: #001219ff;
$blue-sapphire: #005f73ff;
$viridian-green: #0a9396ff;
$middle-blue-green: #94d2bdff;
$light-blue-green: rgb(190, 224, 213);
$medium-champagne: #e9d8a6ff;
$gamboge: #ee9b00ff;
$alloy-orange: #ca6702ff;
$mahogany: #bb3e03ff;
$rufous: #ae2012ff;
$ruby-red: #9b2226ff;

$primary-border-color: $rich-black-fogra-29;
$primary-font-color: $rich-black-fogra-29;
$primary-font-color-active: $middle-blue-green;
$primary-font-color-hover: $rich-black-fogra-29;
$primary-background-color: $viridian-green;
$primary-background-color-active: $blue-sapphire;
$primary-background-color-hover: $light-blue-green;

$border-color: $blue-sapphire;
$font-color: $rich-black-fogra-29;
$font-color-active: $middle-blue-green;
$font-color-hover: $rich-black-fogra-29;
$background-color: $middle-blue-green;
$background-color-active: $blue-sapphire;
$background-color-hover: $light-blue-green;

$responsive-max-width: 600px;
$responsive-max-height: 450px;

$tile-size-portrait: calc(7vw);
$tile-size-landscape: calc(5vw);
$tile-border-radius-portrait: calc($tile-size-portrait / 5); 
$tile-border-radius-landscape: calc($tile-size-landscape / 5); 
