@import './colors.scss';

.tutorial {
  .overlay {
    user-select: none;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba($rich-black-fogra-29, 0.5);

    &.top {
      border-bottom: 2px dotted $gamboge;
    }
    &.bottom {
      border-top: 2px dotted $gamboge;
    }
    &.left {
      border-right: 2px dotted $gamboge;
    }
    &.right {
      border-left: 2px dotted $gamboge;
    }
  }

  .help-text {
    position: fixed;
    user-select: none;

    padding: 8px;
    font-weight: 900;

    top: 0;
    left: 0;
    // right: 0;
    // bottom: 0;
    background-color: rgba(white, 1);
    border: 1px solid $border-color;
  }
}
