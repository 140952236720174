@import '../colors.scss';

.layout {
  --padding: 4px;
  --nav-bar-height: calc(50px + var(--padding) * 2);

  height: 100vh;
  width: 100vw;
  display: grid;
  grid-template-rows: var(--nav-bar-height) calc(100vh - var(--nav-bar-height));
  grid-template-columns: 100vw;
  overflow: hidden;
  & > .nav-bar {
    display: inline-flex;
    justify-content: space-between;
    overflow: hidden;
    padding: var(--padding);
    border-bottom: 2px solid $border-color;
    box-shadow: 5px 5px 50px rgba($rich-black-fogra-29, 0.5);

    & > .left {
      display: inline-flex;
      justify-content: flex-start;
    }
    & > .middle {
      display: inline-flex;
      justify-content: center;
      overflow: auto;
    }
    & > .right {
      display: inline-flex;
      justify-content: flex-end;
    }
  }
  & > .main {
    display: grid;
    grid-template-rows: 100vh;
    grid-template-columns: calc($tile-size-landscape * 6) auto;
    @media screen and (max-aspect-ratio: 1/1) {
      display: flex;
      flex-direction: column-reverse;
      align-content: stretch;
      justify-content: center;
    }

    &.no-left-side {
      grid-template-columns: auto;
    }

    & > .left {
      overflow: hidden;
      padding: var(--padding);
      display: flex;
      flex-direction: row;
    }
    & > .body {
      overflow: hidden;
      padding: var(--padding);
      & > * {
        justify-self: center;
        align-self: center;
      }
    }
  }
}
