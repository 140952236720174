@import '../colors.scss';

.tile {
  border: 1px solid transparent;
  border-radius: $tile-border-radius-landscape;
  height: $tile-size-landscape;
  width: $tile-size-landscape;
  margin: 2px;
  font-size: 12px;
  -moz-box-shadow: inset 0 0 8px $rich-black-fogra-29;
  -webkit-box-shadow: inset 0 0 8px $rich-black-fogra-29;
  box-shadow: inset 0 0 8px $rich-black-fogra-29;
  display: grid;
  align-items: center;
  font-size: 20px;
  color: $rich-black-fogra-29;

  @media screen and (max-aspect-ratio: 1/1) {
    height: $tile-size-portrait;
    width: $tile-size-portrait;
    border-radius: $tile-border-radius-portrait;
  }

  &.clickable {
    cursor: pointer;
  }

  &:hover {
    opacity: 0.9;
  }

  &.selected {
    box-shadow: inset 0 0 8px $medium-champagne;
  }
  &.empty {
    box-shadow: inset 0 0 8px $viridian-green;
    background: none;
  }
  &.inactive {
    opacity: 0.3;

    &:hover {
      opacity: 0.4;
    }
  }
  &.minus-one {
    background: white;
    border: none;
  }
}
