@import './colors.scss';

.stage-row {
  display: inline-flex;
  border: 1px solid transparent;
  flex-direction: row-reverse;

  &:not(.disabled) {
    &:hover {
      border: 1px solid $medium-champagne;
      background-color: rgba($light-blue-green, 0.2);
    }
  }

  &.disabled {
    user-select: none;
  }
}
