.factory {
  height: calc(100vh - var(--nav-bar-height));

  @media screen and (max-aspect-ratio: 1/1) {
    height: unset;
  }

  overflow: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
}
