@import '../colors';

button.azul {
  height: 30px;
  margin: 8px;
  white-space: nowrap;
  user-select: none;
  display: inline-flex;
  column-gap: 4px;
  align-items: center;

  @media screen and (max-width: #{$responsive-max-width}) {
    & > .label.has-icon {
      display: none;
    }
  }

  &:not(.primary) {
    color: $viridian-green;
    border: 1px solid transparent;
    background-color: transparent;

    &:hover {
      cursor: pointer;
      color: $light-blue-green;
    }

    &:active {
      color: $font-color-active;
      background-color: $background-color-active;
    }
  }

  &.primary {
    border: 1px solid $viridian-green;
    color: $viridian-green;
    background-color: transparent;
    font-weight: 900;

    &:hover {
      color: $viridian-green;
      background-color: $primary-background-color-hover;
    }

    &:active {
      color: $primary-font-color-active;
      background-color: $primary-background-color-active;
    }
  }

  &.disabled {
    user-select: none;
    pointer-events: none;
    border: 1px solid transparent;
    color: $light-grey;
    background-color: transparent;

    &:hover {
      color: $primary-font-color-hover;
      background-color: $primary-background-color-hover;
    }

    &:active {
      color: $primary-font-color-active;
      background-color: $primary-background-color-active;
    }
  }
}
