@import './colors';

.score-card {
  height: 20px;
  border: 1px solid $border-color;
  border-radius: 20px;
  margin: 8px;
  padding: 4px;
  font-size: 14px;
  align-content: baseline;
  align-items: baseline;
  display: inline-flex;
  grid-template-columns: auto auto auto auto;

  & > * {
    margin: 0px 4px;
  }

  @media screen and (max-width: #{$responsive-max-width}) {
    & > .player-name {
      display: none;
    }
  }
  & > .score {
    font-weight: 800;
  }
  & > .detail {
    display: none;
    & > * {
      margin: 0px 4px;
    }
  }
}
