@import '../colors.scss';

.azul-input {
  display: grid;
  margin: 8px;

  --azul-input-font-size: 16px;

  & > label {
    margin-bottom: 2px;
    font-size: var(--azul-input-font-size);
    color: $blue-sapphire;
  }
  & > input {
    padding: 4px;
    font-size: var(--azul-input-font-size);
    border: 1px solid $border-color;
    background-color: $light-blue-green;
    color: $rich-black-fogra-29;

    &:hover {
      border-color: $middle-blue-green;
    }

    &:focus {
      outline: none;
    }

    &:active,
    &:focus {
      border: 1px solid $viridian-green;
      background-color: $middle-blue-green;
      // background-color: $light-blue-green;
    }
  }
}
