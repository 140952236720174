@import './colors.scss';

.overflow {
  display: inline-flex;
  border: 1px solid $gamboge;
  margin: 8px;
  padding: 4px;
  align-items: center;
  flex-wrap: wrap;

  &:hover {
    border: 1px solid $medium-champagne;
    background-color: rgba($light-blue-green, 0.2);
  }
}
