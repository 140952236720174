@import '../colors.scss';

.azul-segmented-control {
  display: inline-flex;
  margin: 8px;

  & > button {
    height: 30px;

    &:not(.selected) {
      border: 1px solid $border-color;
      color: $font-color;
      background-color: $background-color;

      &:hover {
        color: $font-color-hover;
        background-color: $background-color-hover;
      }

      &:active {
        color: $font-color-active;
        background-color: $background-color-active;
      }
    }

    &.selected {
      border: 1px solid $primary-border-color;
      color: $primary-font-color;
      background-color: $primary-background-color;

      &:hover {
        color: $primary-font-color-hover;
        background-color: $primary-background-color-hover;
      }

      &:active {
        color: $primary-font-color-active;
        background-color: $primary-background-color-active;
      }
    }
  }
}
