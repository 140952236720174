@import '../colors.scss';

.modal {
  .overlay {
    user-select: none;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba($rich-black-fogra-29, 0.5);
  }
  .content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    min-width: 100px;
    min-height: 100px;
    border: 1px solid $border-color;
    background: white;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    outline: none;
    // box-shadow: 10px 5px 40px rgba($medium-champagne, 0.5);
    box-shadow: 10px 5px 40px $rich-black-fogra-29;

    display: grid;
    grid-template-columns: 200px auto;

    &.no-left {
      grid-template-columns: auto;
    }

    & > .left {
      border-right: 2px solid $border-color;
      padding: 8px;
    }

    & > .right {
      display: grid;
      grid-template-rows: 50px auto 50px;

      &.no-header {
        grid-template-rows: auto 50px;
      }

      & > .header {
        border-bottom: 2px solid $border-color;
        padding: 8px;
        display: inline-flex;
        align-items: center;
        color: $viridian-green;
        font-weight: 600;
      }

      & > .body {
        overflow: auto;
        padding: 4px;
        max-height: 70vh;
        max-width: 80vw;
        display: contents;
      }

      & > .footer {
        display: inline-flex;
        justify-content: flex-end;
        padding: 4px;
      }
    }
  }
}
